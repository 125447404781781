import { Device as pluginDevice } from "@capacitor/device";
import omit from "lodash/omit";

async function getInfo(): Promise<DeviceInfo> {
  const deviceInfo = await pluginDevice.getInfo();
  return omit(deviceInfo, ["diskFree", "diskTotal"]);
}

export const device: Device.Implementation = {
  getInfo
};
