import { initializeApp } from "firebase/app";

export const config = {
  apiKey: "AIzaSyA7zFh75VL87xMhNmfbxsiJOcUsHECGv8M",
  authDomain: "inventory-shield.firebaseapp.com",
  databaseURL: "https://inventory-shield.firebaseio.com",
  projectId: "inventory-shield",
  storageBucket: "inventory-shield.appspot.com",
  messagingSenderId: "49614934568",
  appId: "1:49614934568:web:a3f7007c275b6d24ae9b1d",
  measurementId: "G-RSTE2EB6Z3"
};

export const app = initializeApp(config);

export const USE_FIREBASE_EMULATORS = import.meta.env.VUE_APP_USE_FIREBASE_EMULATORS === "true";
