/*
eslint-disable
no-console,
@typescript-eslint/no-unsafe-argument,
@typescript-eslint/no-explicit-any
*/
import { ContextError } from "@/errors/ContextError";

let enabled = true;

export const enum LogLevel {
  trace,
  debug,
  info,
  warn,
  error
}

// Default logging level is 'warn'
let logLevel: LogLevel = LogLevel.warn;

/**
 * Set the logging level.
 * @param newLogLevel
 */
function setLevel(newLogLevel: LogLevel) {
  logLevel = newLogLevel;
}

/**
 * Send a trace message to the console.  In Chrome dev tools, this is an Info log level.
 * @param message
 * @param optionalParams
 */
function trace(message?: any, ...optionalParams: any[]) {
  if (!enabled || logLevel > LogLevel.trace) {
    return;
  }
  console.trace(message, ...optionalParams);
}

/**
 * Send a debug message to the console.  In Chrome dev tools, this is a Verbose log level.
 * @param message
 * @param optionalParams
 */
function debug(message?: any, ...optionalParams: any[]) {
  if (!enabled || logLevel > LogLevel.debug) {
    return;
  }
  console.debug(message, ...optionalParams);
}

/**
 * Send an info message to the console.  In Chrome dev tools, this is an Info log level.
 * @param message
 * @param optionalParams
 */
function info(message?: any, ...optionalParams: any[]) {
  if (!enabled || logLevel > LogLevel.info) {
    return;
  }
  console.info(message, ...optionalParams);
}

/**
 * Send a warning message to the console.  In Chrome dev tools, this is a Warning log level.
 * @param message
 * @param optionalParams
 */
function warn(message?: any, ...optionalParams: any[]) {
  if (!enabled || logLevel > LogLevel.warn) {
    return;
  }
  console.warn(message, ...optionalParams);
}

/**
 * Send an error message to the console.  Has special formatting if a `ContextError` is passed as one of the `optionalParams`.
 * In Chrome dev tools, this is an Error log level.
 * @param message
 * @param optionalParams
 */
function error(message?: any, ...optionalParams: any[]) {
  if (!enabled || logLevel > LogLevel.error) {
    return;
  }

  const contextError = optionalParams.find(p => p instanceof ContextError) as ContextError;
  if (contextError) {
    const otherParams = optionalParams.filter(p => !(p instanceof ContextError));
    if (otherParams.length > 0) {
      console.error(message, contextError.context, contextError, ...otherParams);
    } else {
      console.error(message, contextError.context, contextError);
    }
  } else {
    if (optionalParams.length > 0) {
      console.error(message, ...optionalParams);
    } else {
      console.error(message);
    }
  }
}

/**
 * Enable remote logging
 */
function enable(): void {
  enabled = true;
}

/**
 * Disable remote logging
 */
function disable(): void {
  enabled = false;
}

export const log = {
  setLevel,
  enable,
  disable,
  trace,
  debug,
  info,
  warn,
  error
};
