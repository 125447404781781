import { Capacitor } from "@capacitor/core";

function getPlatform(): Platform.DeviceType | undefined {
  const platform = Capacitor.getPlatform();
  if (isMobilePlatform(platform) || isWebPlatform(platform)) {
    return platform;
  }

  return undefined;
}

function isMobilePlatform(d: string | undefined): d is "ios" | "android" {
  return d === "ios" || d === "android";
}

function isWebPlatform(d: string | undefined): d is "web" {
  return d === "web";
}

const isIOS = getPlatform() === "ios";
const isAndroid = getPlatform() === "android";
const isWeb = isWebPlatform(getPlatform());
const isMobile = isMobilePlatform(getPlatform());

export const platform: Platform.Implementation = {
  getPlatform,
  isMobile,
  isWeb,
  isIOS,
  isAndroid,
  isWebPlatform
};
