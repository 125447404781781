import firebase from "firebase/compat/app";
import "firebase/compat/analytics";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import { app, config, USE_FIREBASE_EMULATORS } from "./app";
import { callableFunctionsFactory } from "./callableFunctionsFactory";
import { connectFunctionsEmulator, getFunctions } from "firebase/functions";

export const LOCATION_SEARCH_INDEX = "locations";
export const EQUIPMENT_SEARCH_INDEX = "equipment";

export * from "./app";

export { FirebaseError } from "@firebase/util";

const legacyApp = firebase.initializeApp(config);

// Analytics
if (firebase.analytics?.isSupported) {
  void firebase.analytics.isSupported().then(isSupported => {
    if (isSupported) {
      firebase.analytics();
    }
  });
}

// Auth
export * from "./auth";

/**
 * Gets an auth instance from the Firebase app.
 */
export function getAuth(): firebase.auth.Auth {
  const auth = firebase.auth();
  auth.useDeviceLanguage();

  // If this instance hasn't already connected to the emulator,
  // and we have emulators enabled, connect the emulator:
  if (USE_FIREBASE_EMULATORS && !auth.emulatorConfig) {
    auth.useEmulator("http://localhost:9099/");
  }

  return auth;
}

// Firestore
export const firestore = firebase.firestore(legacyApp); // legacy
export const FirebaseFirestore = firebase.firestore;

// Functions
const initFunctions = getFunctions(app);
export const callableFunctions = callableFunctionsFactory(initFunctions);

// Storage
export const storage = firebase.storage(legacyApp);
export const TaskState = firebase.storage.TaskState;
export const TaskEvent = firebase.storage.TaskEvent;

if (USE_FIREBASE_EMULATORS) {
  firestore.settings({
    host: "localhost:5002",
    ssl: false,
    merge: true
  });
  connectFunctionsEmulator(initFunctions, "localhost", 5001);
  storage.useEmulator("localhost", 9199);
}

export default legacyApp;
