import { App } from "@capacitor/app";

async function getInfo(): Promise<BuildInfo> {
  return await App.getInfo();
}

async function resumeListener(
  resumeHandler: AppDetails.ResumeListener
): Promise<PluginListenerHandle> {
  return await App.addListener("resume", resumeHandler);
}

export const app: AppDetails.Implementation = {
  getInfo,
  resumeListener
};
