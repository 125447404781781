/**
 * Error class that allows a `cause` and `context`.
 */
export class ContextError extends Error {
  public readonly context?: object;

  /**
   * Creates a `ContextError` that references another error as the cause if it was passed in
   * as well as a context that has context parameters from the caller.
   * @param message
   * @param options
   */
  constructor(message: string, options: { cause?: Error; context?: object } = {}) {
    const { cause, context } = options;

    super(message, { cause });
    this.name = this.constructor.name;

    this.context = context;
  }
}
