import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { startWatchingFirebaseIndexedDBAndCacheItToLocalStorage } from "@/firebaseAuthCache";
import { platform } from "@/capacitorPlugins";

if (platform.isMobile) {
  startWatchingFirebaseIndexedDBAndCacheItToLocalStorage();
}

export type FirebaseUser = firebase.User;

export async function applyActionCode(auth: firebase.auth.Auth, code: string): Promise<void> {
  return await auth.applyActionCode(code);
}

export async function createUserWithEmailAndPassword(
  auth: firebase.auth.Auth,
  email: string,
  password: string
): Promise<firebase.auth.UserCredential> {
  return await auth.createUserWithEmailAndPassword(email, password);
}

export const EmailAuthProvider = firebase.auth.EmailAuthProvider;

export function onAuthStateChanged(
  auth: firebase.auth.Auth,
  nextOrObserver: firebase.Observer<unknown, Error> | ((a: firebase.User | null) => unknown)
): firebase.Unsubscribe {
  return auth.onAuthStateChanged(nextOrObserver);
}

export async function reauthenticateWithCredential(
  user: firebase.User,
  credential: firebase.auth.AuthCredential
): Promise<ReplaceWith<firebase.auth.UserCredential, "user", firebase.User>> {
  const result = await user.reauthenticateWithCredential(credential);
  const _user = result.user;
  if (!_user) {
    throw new TypeError(
      `Internal Firebase Error: unexpected value for user after sign in: ${JSON.stringify(_user)}`
    );
  }
  return { ...result, user: _user };
}

export async function sendEmailVerification(
  user: firebase.User,
  config: firebase.auth.ActionCodeSettings
): Promise<void> {
  return await user.sendEmailVerification(config);
}

export async function sendPasswordResetEmail(
  auth: firebase.auth.Auth,
  email: string,
  actionCodeSettings?: firebase.auth.ActionCodeSettings | null | undefined
): Promise<void> {
  return await auth.sendPasswordResetEmail(email, actionCodeSettings);
}

export async function signInWithCredential(
  auth: firebase.auth.Auth,
  credential: firebase.auth.AuthCredential
): Promise<ReplaceWith<firebase.auth.UserCredential, "user", firebase.User>> {
  const result = await auth.signInWithCredential(credential);
  const user = result.user;
  if (!user) {
    throw new TypeError(
      `Internal Firebase Error: unexpected value for user after sign in: ${JSON.stringify(user)}`
    );
  }
  return { ...result, user };
}

export async function signOut(auth: firebase.auth.Auth): Promise<void> {
  return await auth.signOut();
}

export async function updateEmail(user: firebase.User, newEmail: string): Promise<void> {
  return await user.updateEmail(newEmail);
}

export async function updatePassword(user: firebase.User, newPassword: string): Promise<void> {
  return await user.updatePassword(newPassword);
}

export async function updateProfile(
  user: firebase.User,
  profile: { displayName?: string | null | undefined; photoURL?: string | null | undefined }
): Promise<void> {
  return await user.updateProfile(profile);
}
