import { reload, sync, setConfig, getConfig as getConfigPlugin } from "@capacitor/live-updates";
import { app } from "@/capacitorPlugins";

async function initializeLiveUpdates(): Promise<void> {
  const store = await import("@/store");
  await app.resumeListener(async () => {
    if (!store.default.getters.hasGoodSidenavNotification) {
      if (await isUpdateAvailable()) {
        await store.default.dispatch.updateAvailable();
      }
    }
  });

  if (await isUpdateAvailable()) {
    await store.default.dispatch.updateAvailable();
  }
  await store.default.dispatch.updateLiveUpdateConfig();
}

async function isUpdateAvailable(): Promise<boolean> {
  const result = await sync();
  return result.activeApplicationPathChanged;
}

async function reloadApp(): Promise<void> {
  await reload();
}

async function setChannel(channel: string): Promise<void> {
  const store = await import("@/store");
  await setConfig({
    channel
  });
  await store.default.dispatch.updateLiveUpdateConfig();
}

async function getConfig(): Promise<LiveUpdates.Config> {
  const config = await getConfigPlugin();
  return {
    channel: config.channel ?? "none",
    autoUpdateMethod: config.autoUpdateMethod
  };
}

export const liveUpdates: LiveUpdates.Implementation = {
  reloadApp,
  initializeLiveUpdates,
  isUpdateAvailable,
  setChannel,
  getConfig
};
